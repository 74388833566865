.form {
    width: 500px;
    background: #ffffff;
    margin-left: auto;
    padding: 30px;
    color: #000;
    border-radius: 9px;
}

.form-header{
    text-align: center;
    padding-bottom: 10px;
}

.form h4 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
}

.form-input {
    margin-bottom: 10px;
    text-align: center;
}

form p {
    font-size: 0.7em;
    margin-top: -5px;
    font-style: italic;
}

.form-input .btn {
    background: #E77C40;
    color: #ffffff;
    width: 250px;
    height: 45px;
    font-weight: 700;
}

@media (width < 769px){
    .form {
        width: 100%;
        margin: auto;
        margin-top: 110px;
    }

    #daftar {
        padding-top: 50px;
    }

    .form-input .btn {
        width: 200px;
    }

    #persyaratan{
        padding-top: 100px;
    }
}
@media (width > 767px){
    .form {
        margin-top: 0;
        width: 100%;
    }
}
@media (width > 1023px){
    .form {
        width: 350px;
    }
}

@media (width > 1439px){
    .form {
        width: 400px;
    }
}