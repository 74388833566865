* {
    margin: 0;
    padding: 0;
}

.navbar {
    height: 100px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0x;
    right: 0px;
    width: 100%;
    transition: 0.2s ease-in-out;
    z-index: 11;
}

.navbar.active {
    background-color: #fff;
    transition: 0.6s ease-in-out;
}

.navbar a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 1.2em;
}

.navbar a:hover{
    color: #000;
    transition: 0.2s ease-in;
}

.nav-brand {
    font-weight: 700;
}

.nav-brand:hover {
    color: #db2727;
    transition: 0.4s ease-out;
    transition: 0.4s ease-out;
}

.nav-brand.active {
    color: #db2727;
}

.nav-brand.active:hover {
    color: #000;
    transition: 0.4s ease-out;
    
}

.nav-list {
    display: flex;
    justify-content: space-around;
    margin: auto;
    padding: 0;
}

.navlink.active {
    color: #000;
}

.nav-list li {
    list-style: none;
    position: relative;
    margin-left: 20px;
}

.nav-list li a {
    text-decoration: none;
}

.burger-menu {
    display: none;
}

@media (width < 769px) {

    .navbar {
        height: 120px;
    }

    .nav-brand {
        margin: auto;
        font-size: 1em;
    }
    .navbar.active {
        height: 80px;
    }
    .nav-list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        position: fixed;
        top: 80px;
        right: 0px;
        width: 300px;
        height: 100vh;
        transition: 0.5s ease-in-out;
        padding: 20px;
        background-color: #ffffff;
    }

    .nav-list li {
        margin-bottom: 10px;
    }

    .nav-list li a{
        color: #000;
        font-size: 1.5em;
        font-weight: 700;
    }   

    .burger-menu {
        display: block;
        color: #ffffff;
        font-size: 2em;
    }

    .burger-menu.active {
        color: #000;
    }

    .nav-list.active {
        position: fixed;
        right: -300px;
    }
}

@media (width > 767px) {
    .nav-brand {
        font-size: 1.2em;
    }
}

@media (width > 1024px) {

}
