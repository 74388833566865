.card-tahap {
    /* height: 400px; */
    /* background: #000; */
    width: 380px;
    border: 1px solid #A9D6FF;
    border-radius: 15px 15px 15px 15px;
    margin: auto;
}

.card-tahap .header{
    height: 130px;
    background: #252B42;
    border-radius: 15px 15px 0 0;
    display: flex;
    align-items: center;
    padding: 0 50px;
}

.rounded-user {
    background-color: #fff;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.rounded-user i {
    color: #252B42;
    font-size: 1.5em;
}

.text-tahap h5 {
    color: #fff;
    font-weight: 600;
    width: 90px;
}

.card-tahap .body {
    display: flex;
    height: 80px;
    padding: 0 50px;
    border-radius: 0 0 15px 15px;
}

.body p {
    margin: auto 0;
    font-weight: 700;
    color: #1C2172;
}



@media (width < 769px) {
    .card-tahap {
        width: 290px;
        margin-bottom: 20px;
    }
    .text-tahap h5 {
        font-size: 1em;
    }

    .card-tahap .header {
        padding: 0 30px;
    }

    .card-tahap .body {
        padding: 0 30px;
    }
}

@media (width > 767px) {

    .card-tahap {
        width: 380px;
        margin-bottom: 50px;
    }

    .text-tahap h5{
        width: 180px;
    }

    .card-tahap .header {
        padding: 0 50px;
    }

    .card-tahap .body {
        padding: 0 50px;
    }
}
@media (width > 1023px) {
    .card-tahap {
        width: 100%;
    }

    .text-tahap h5 {
        width: 150px;
        font-size: 1em;
    }

    .card-tahap .header {
        padding: 0 10px;
    }

    .card-tahap .body {
        padding: 0 30px;
    }
}